import { useEffect } from 'react'
import { useRouter } from 'next/navigation'
import { useAppLoaderContext } from '@/context/AppLoaderContext'

export const useShareComponentEvents = () => {
  const router = useRouter()
  const { setIsAppLoading } = useAppLoaderContext()

  useEffect(() => {
    type ChangeRouteArgs = {
      origin: string
      path: string
    }

    function handleCreateWorld() {
      router.push('/user-worlds?create')
    }

    function handleChangeRoute(event: CustomEvent<ChangeRouteArgs>) {
      const origin = event.detail.origin
      const path = event.detail.path

      setIsAppLoading(true)

      if (window.location.origin === origin) {
        router.push(path)
      } else {
        window.location.href = `${origin}${path}`
      }

      setIsAppLoading(false)
    }

    // 🌎 handle CustomEvent from share-component
    document.addEventListener('createWorld', handleCreateWorld)
    document.addEventListener('onChangeRoute', handleChangeRoute as EventListener)

    return () => {
      document.removeEventListener('createWorld', handleCreateWorld)
      document.removeEventListener('onChangeRoute', handleChangeRoute as EventListener)
    }
  }, [router, setIsAppLoading])
}
