'use client'

import { PropsWithChildren, createContext, useContext, useState } from 'react'

type LayoutContextType = {
  headerHeight: number
  setHeaderHeight: (height: number) => void
}

const LayoutContext = createContext<LayoutContextType | null>(null)

export function LayoutProvider({ children }: PropsWithChildren) {
  const [headerHeight, setHeaderHeight] = useState(0)

  return (
    <LayoutContext.Provider
      value={{
        headerHeight,
        setHeaderHeight,
      }}
    >
      {children}
    </LayoutContext.Provider>
  )
}

export const useLayoutContext = () => {
  const value = useContext(LayoutContext)

  if (value == null) {
    throw new Error('useLayoutContext cannot be used outside of LayoutProvider')
  }

  return value
}
