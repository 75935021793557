'use client'

import { useRef, useEffect, useState } from 'react'

import { useConfigsContext } from '@/context/ConfigsContext'
import { useAuthContext } from '@/context/AuthContext'
import { useVSCSdkContext } from '@/context/VSCSdkContext'
import { useShareComponentProfile } from '@/hooks/useShareComponentProfile'
import { useCalculateHeaderHeight } from '@/hooks/useCalculateHeaderHeight'
import { useShareComponentEvents } from '@/hooks/useShareComponentEvents'
import { useLayoutContext } from '@/context/LayoutContext'
import ViverseCommunityDialog from '@/components/ViverseCommunityDialog'

function Header() {
  const headerRef = useRef<HTMLDivElement>(null)
  const [openCommunityDialog, setOpenCommunityDialog] = useState(false)

  const { store } = useConfigsContext()
  const { isSignedIn, handleLogin, handleLogout } = useAuthContext()
  const { viverseShareComponent, isMountedHeader, setIsMountedHeader, changeLocale } =
    useVSCSdkContext()
  const { headerHeight } = useLayoutContext()
  const shareComponentProfile = useShareComponentProfile()
  const communityToggle = store?.communityToggle

  useCalculateHeaderHeight(headerRef)
  useShareComponentEvents()

  useEffect(() => {
    if (isMountedHeader && viverseShareComponent) {
      viverseShareComponent.updateProfile(shareComponentProfile)
    }
  }, [isMountedHeader, viverseShareComponent, isSignedIn, shareComponentProfile])

  useEffect(() => {
    if (viverseShareComponent && headerRef.current) {
      viverseShareComponent.mountHeader({
        $header: headerRef.current,
        localeConfig: {
          changeLocale,
        },
        authConfig: {
          onLogin: handleLogin,
          onLogout: handleLogout,
          profile: shareComponentProfile,
        },
        layoutConfig: {
          type: 6,
          community: {
            isChinaUser: !communityToggle,
            showIframe: () => setOpenCommunityDialog(true),
          },
        },
      })

      setIsMountedHeader(true)
    }
  }, [
    handleLogin,
    handleLogout,
    setIsMountedHeader,
    shareComponentProfile,
    viverseShareComponent,
    communityToggle,
    changeLocale,
  ])

  return (
    <>
      <header
        ref={headerRef}
        className="fixed top-0 z-50 min-h-[48px] w-full bg-transparent transition-all lg:min-h-[58px]"
        style={{
          maxHeight: headerHeight,
        }}
      />
      <ViverseCommunityDialog open={openCommunityDialog} onOpenChange={setOpenCommunityDialog} />
    </>
  )
}

export default Header
