import { RefObject, useEffect } from 'react'
import { useResizeObserver } from 'usehooks-ts'
import { useLayoutContext } from '@/context/LayoutContext'

export const useCalculateHeaderHeight = (headerRef: RefObject<HTMLElement>) => {
  const { setHeaderHeight } = useLayoutContext()
  const { height: headerHeight = 0 } = useResizeObserver({
    ref: headerRef,
    box: 'border-box',
  })

  useEffect(() => {
    setHeaderHeight(headerHeight)
  }, [headerHeight, setHeaderHeight])
}
